@use 'styles/themes/themes.scss';

.container {
  width: 100%;
  padding-top: 10px;
  position: relative;
}

.background {
  position: absolute;
  top: 0;
  @include themes.themify('background', 'tertiary');
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 40px;
  z-index: 1;
  border-radius: 50px;
}

.steps {
  display: flex;
  align-items: center;
  z-index: 2;
  padding: 0 5px;
}
