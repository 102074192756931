@use 'src/styles/themes/themes';
@use 'src/styles/fonts';

.text {
  @include fonts.get-font-size('small');
  width: 30px;
  line-height: 30px;
}

.popper {
  z-index: 5;
}

.icon {
  max-width: 18px;
  max-height: 18px;
  opacity: 0.3;
  position: absolute;
  right: 13px;
  top: 6px;
}
