@use "../../../styles/utils";
@use "../../../styles/themes/themes";
@import '../../../styles/fonts.scss';

.container {
  display: flex;
  flex-direction: column;
  @include themes.themify('color', 'subtitle');
  font-size: 16px;

  label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: bold;
    font-family: $font-regular;
    @include themes.themify('color', 'primary');
  }

  p {
    line-height: 21px;
    margin-bottom: 22px;
  }
}

.container.axa{
  label{
    @include themes.themify('color', 'const_axa_blue');
  }
}
