@use 'src/styles/fonts';
@use 'src/styles/themes/themes';
@import '../../styles/media_queries.scss';

.container {
  @include themes.themify('background-color', 'alert');
  @include themes.themify('color', 'background');
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  animation: appear ease-out 1.5s;
  height: 60px;
  overflow: hidden;

  @media screen and (max-width: $width-large) {
    height: 80px;
  }

  p {
    @include fonts.get-font-size('p');
    @include themes.themify-fonts('regular');
  }
}

@keyframes appear {
  0% {
    height: 0px;
  }
  100% {
    height: 60px;
    @media screen and (max-width: $width-large) {
      height: 80px;
    }
  }
}
