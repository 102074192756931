@use "src/styles/themes/themes";
@use "src/styles/fonts";
@use "sass:map";

@include themes.themify-all-colors('color_');

.font_light {
  @include themes.themify-fonts('light');
}

.font_bold {
  @include themes.themify-fonts('bold');
}

.font_semibold {
  @include themes.themify-fonts('semibold');
}

.font_medium {
  @include themes.themify-fonts('medium');
}

.font_regular {
  @include themes.themify-fonts('regular');
}

.h1 {
  font-size: map.get(fonts.$size, "h1");
}

.h2 {
  font-size: map.get(fonts.$size, "h2");
}

.h3 {
  font-size: map.get(fonts.$size, "h3");
}

.h4 {
  font-size: map.get(fonts.$size, "h4");
}

.h5 {
  font-size: map.get(fonts.$size, "h5");
}

.h6 {
  font-size: map.get(fonts.$size, "h6");
}

.p, .span, .em, .strong {
  font-size: map.get(fonts.$size, "p");
}

.small {
  font-size: map.get(fonts.$size, "small");
}

.label {
  font-size: map.get(fonts.$size, "label");
}