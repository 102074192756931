@use "src/styles/utils";
@use "src/styles/themes/themes";

.container {
  @include utils.boxShadow;
  margin-bottom: 18px;
  padding: 26px 35px;
  border-radius: 3px;
  background: #fff;
}

.titleContainer {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}

.containerForm {
  margin-top: 15px;
  padding-top: 4px;

  border-top: 1px solid;
  @include themes.themify('border-top-color', 'base_gray');
}

.footer {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  margin-top: 30px;
  
  border-top: 1px solid;
  @include themes.themify('border-top-color', 'base_gray');

  @media (max-width: 575px) {
    flex-direction: column;
  }
}

.divButton {
  width: 180px;
  height: 53px;

  @media (max-width: 575px) {
    width: 100%;
    &:first-child {
      margin-bottom: 10px;
    }
  }

  button {
    width: 100%;
    height: 100%;
  }
}

.disabledButtonContainer {
  opacity: 0;

  @media (max-width: 575px) {
    display: none;
  }
}

.mandatoryDisclaimer {
  padding-bottom: 16px;
}