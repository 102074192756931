.bigger {
  width: 20rem;
  height: 3.5rem;

  svg {
    width: 20rem;
    height: 3.5rem;
  }

  @media screen and (max-width: 600px) {
    width: 10rem;
    height: 2.8rem;
    svg {
      width: 15rem;
      height: 2.8rem;
      pointer-events: none;
    }
    .textLogoVisa {
      display: none;
    }
  }
}
.small {
  width: 15rem;
  height: 2.8rem;
  svg {
    width: 15rem;
    height: 2.8rem;
    pointer-events: none;
  }
  .textLogoVisa {
    display: none;
  }
}

.medium {
  width: 9rem;
  height: 3rem;

  svg {
    width: 18rem;
    height: 3rem;
  }
  .textLogoVisa {
    display: none;
  }

  @media screen and (max-width: 600px) {
    width: 8rem;
    height: 2.8rem;
    svg {
      width: 15rem;
      height: 2.8rem;
      pointer-events: none;
    }
    .textLogoVisa {
      display: none;
    }
  }
}
