@use 'styles/themes/themes.scss';
@use 'styles/utils.scss';
@use 'styles/fonts.scss';
@import '../../styles/fonts.scss';

.tooltip {
  position: absolute;
  top: 8px;
  right: 8px;
  left: 0;
  height: 0;
  padding: 0 0.75rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 0;
}

.searchIcon {
  width: 18px;
  height: 18px;
  @include themes.themify('color', 'secondary');
}

.tooltipIcon {
  width: 18px;
  height: 18px;
  margin-top: 16px;
}

.disabled {
  cursor: not-allowed;
}

[data-tooltip] {
  @include themes.themify('background-color', 'background', '&:hover:after');
  &:hover:after {
    display: block;
    position: absolute;
    z-index: 100;
    font-family: $font-light;
    -webkit-box-shadow: 0px 9px 11px 5px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0px 9px 11px 5px rgba(0, 0, 0, 0.18);
    box-shadow: 0px 9px 11px 5px rgba(0, 0, 0, 0.18);
    border-radius: 0.2em;
    content: attr(datatitle);
    margin-top: -35px;
    padding: 0.4em;
    width: 100%;
    top: 62px;
    right: 0;
  }
}
