@use "src/styles/themes/themes";
@use "src/styles/fonts";

.container {
  margin-top: 26px;
  margin-bottom: 26px;
  position: relative;
}

.input {
  width: 100%;
  border: none;
  appearance: none;
  border-bottom: 1px solid;
  height: 35px;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  @include themes.themify('border-color', 'primary');
  @include themes.themify('color', 'primary');
  @include fonts.get-font-size('label');

  &:focus {
    @include themes.themify('border-color', 'secondary');
  }

  &.error {
    @include themes.themify('border-color', 'alert');
  }
}

.icon {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 8px;
  top: 6px;
  z-index: -1;
  @include themes.themify('color', 'secondary');
}
