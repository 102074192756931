@use '../../styles/themes/themes.scss';
@import '../../styles/media_queries.scss';
@import '../../styles/fonts.scss';

.container {
  border-radius: 3px;
  width: 50%;
  flex: 1;
  height: 288px;
  max-width: 3500px;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  padding: 4%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;

  &.marginRight {
    margin-right: 6%;
    @media (max-width: $width-small) {
      margin-right: 0;
      margin-bottom: 6%;
    }
  }

  .infoContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .amountText {
    font-size: 6rem;
    font-family: $font-light;
  }
  .label {
    font-size: 2.2rem;
    @include themes.themify('color', 'subtitle');
  }
  .labelLink {
    text-decoration: none;
    font-size: 1.6rem;
    font-weight: 600;
    font-family: $font-regular;
    @include themes.themify('color', 'link');

    &:hover {
      text-decoration: underline;
    }
  }
}

.container.big {
  width: 100%;
  padding: 2%;

  &.marginRight {
    margin-right: 3%;
    @media (max-width: $width-small) {
      margin-bottom: 3%;
    }
  }
}

.infoContainerDouble {
  width: 100%;
  display: flex;
  gap: 4%;

  .amounContainer {
    flex: 1;

    .amount {
      flex: 1;
    }
  }
}

@media (max-width: $width-medium) {
  .container {
    .amountText {
      font-size: 3rem;
    }
  }

  .container.big {
    .infoContainerDouble {
      flex-direction: column;

      .amounContainer {
        margin-top: 30px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
@media (max-width: $width-small) {
  .container {
    width: 100%;
    .amountText {
      font-size: 3rem;
    }
  }

  .infoContainerDouble {
    flex-direction: column;

    .amounContainer {
      margin-top: 30px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
