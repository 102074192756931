@use '../../styles/themes/themes.scss';
@use '../../styles/fonts.scss';

@include themes.themify-all-colors('','background-color');

.container {

  height: fit-content;
  width: fit-content;
  min-width: 80px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 13px;
  span {
    color: white;
    @include fonts.get-font-size('small');
    margin: 2px 5px;
  }
}
