@use '../../styles/themes/themes.scss';
@import '../../styles/media_queries.scss';

.container {
  .header {
    height: 80px;
    background: transparent;
    display: flex;
    align-items: center;

    position: absolute;

    @media screen and (min-width: $width-extra-large) {
      padding-left: 9rem;
    }

    @media screen and (max-width: $width-extra-large) {
      padding-left: 2rem;
    }

    .logo {
      width: 130px;
    }
  }

  .body {
    height: calc(100vh);
    display: flex;

    @media screen and (max-width: $width-extra-large) {
      flex-direction: column;
    }

    .left {
      flex: 1;

      display: flex;
      flex-direction: column;
      justify-content: center;

      @media screen and (min-width: $width-extra-large) {
        padding: 8rem 0 0 9rem;
      }

      @media screen and (max-width: $width-extra-large) {
        padding: 8rem 0 0 2rem;
      }

      @media screen and (max-width: $width-medium) {
        text-align: center;
        align-items: center;
        padding: 8rem 2rem 0;
      }
    }

    .rightBlue {
      flex: 1;
      @include themes.themify('background-color', 'primary');
    }
  }
}
