.container {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
      width: 40px;
      img {
        width: 100%;
        max-width: 40px;
      }
    }

    .closeIcon:hover{
      cursor: pointer;
    }
  }

  .onlyCloseButton {
    justify-content: right;
  }

  .leftBox {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    .icon {
      height: 40px;
      width: fit-content;
    }

    .textContent {
      margin: 30px 0 50px;

      h2 {
        font-size: 30px;
      }
    }
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
  }

  .button {
    width: 180px;

    &:first-child {
      margin-right: 16px;
    }
  }

  .topPadding {
    padding-top: 1rem;
  }
}
