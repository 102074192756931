@use '../../styles/themes/themes.scss';
@import '../../styles/media_queries.scss';


.backgroundBlue{
  height: 80px;
  width: 100%;
  @include themes.themify('background-color', 'primary');
  color: #fff;
  display: flex;
  align-items: center;
  margin-top: 80px;
  padding: 0 9rem;

  @media screen and (max-width: $width-extra-large) {
    padding: 0 2rem;
  }
}
