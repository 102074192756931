.loadingContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: rgba(255, 255, 255, 0.8);
  padding-left: 60px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;

  h1 {
    font-size: 45px;
    margin: 15px 0;
  }

  .dot {
    animation: showAndHidden 1s linear infinite;

    &:nth-child(1) {
      animation-delay: -1.2s;
    }
    &:nth-child(2) {
      animation-delay: -0.8s;
    }
    &:nth-child(3) {
      animation-delay: -0.4s;
    }
  }
}

@keyframes showAndHidden {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
