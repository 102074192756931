@use 'styles/themes/themes.scss';
@use 'styles/media_queries.scss';

.container {
  flex: 1;
  margin: 0 5px;
  z-index: 2;
  display: flex;
}

.item {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@include themes.themify-all-colors('', 'fill');

.dot {
  margin-bottom: 15px;
  width: 20px;
  height: 20px;
}

@media screen and (max-width: media_queries.$width-small) {
  .label {
    display: none;
  }
}
