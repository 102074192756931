@use '../../../../styles/themes/themes.scss';

.itemContainer {
  .footer {
    padding: 15px 0;
    border-top: 1px solid;
    @include themes.themify('border-color', 'secondary');
    display: flex;
    width: 100%;
    margin: 20px 0;

    button{
      padding-right: 40px;
    }
  }
}
