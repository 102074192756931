@use 'src/styles/utils';
@use 'src/styles/fonts';
@use 'src/styles/themes/themes';
@import '../../styles/media_queries.scss';

.Container {
  margin-bottom: 30px;
}

.items {
  align-items: center;
  margin-bottom: 40px;
}

.filterAera {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  .icon {
    width: 100%;
    display: inline-block;
    text-align: center;
    padding-top: 2px;
  }

  .iconFill {
    @include themes.themify('fill', 'secondary', 'path');
  }

  .iconNoFill {
    @include themes.themify('fill', 'primary', 'path');
  }

}
