@import '../../styles/media_queries.scss';

.container {
  @media screen and (max-width: $width-medium) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title {
    font-size: 45px;
    margin-bottom: 20px;
  }
  .description {
    margin-bottom: 50px;
  }

  .wrapperButton {
    width: 200px;
  }
}

.fitDescription {
  max-width: 90%;
}
