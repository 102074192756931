@mixin boxShadow($alpha: 0.1) {
  box-shadow: 0px 0px 50px rgba(0, 0, 0, $alpha);
}

@mixin absoluteFill() {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}