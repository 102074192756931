@import '../../styles/style.scss';
@import '../../styles/fonts.scss';
@import '../../styles/themes/themes.scss';

.button {
  cursor: pointer;
  width: 100%;
  height: 53px;
  @include themify-fonts('regular');
  font-weight: 400;
  font-size: 16px;
  outline: none;
  transition: all linear 0.2s;

  &:focus {
    outline: none;
  }
}

.borderSecondary {
  background: transparent;
  @include themify('color', 'secondary');
  border: 2px solid;
  @include themify('border-color', 'secondary');
  &:hover {
    @include themify('color', 'primary');
    @include themify('background', 'secondary');
  }
}

.borderSecondaryDashed{
  background: transparent;
  @include themify('color', 'secondary');
  border: 2px dashed;
  @include themify('border-color', 'secondary');
  &:hover {
    @include themify('color', 'primary');
    @include themify('background', 'secondary');
  }
}

.borderAlert {
  background: transparent;
  @include themify('color', 'alert');
  border: 2px solid transparent;
  @include themify('border-color', 'gray');
  @include themify('border-color', 'alert');
  &:hover {
    @include themify('color', 'tertiary');
    @include themify('background', 'alert');
  }
}

.primary {
  @include themify('background', 'primary');
  color:  white;
  border: 2px solid;
  @include themify('border-color', 'primary');
  font-weight: 600;
  &:hover {
    border: 2px solid;
    @include themify('color', 'tertiary');
  }
}

.borderPrimary {
  background: transparent;
  @include themify('color', 'primary');
  font-family: $font-regular;
  font-weight: 600;
  border: 2px solid;
  @include themify('border-color', 'primary');
  &:hover {
    @include themify('color', 'tertiary');
    @include themify('background', 'primary');
  }
}

.borderPrimaryDashed{
  background: transparent;
  @include themify('color', 'primary');
  font-family: $font-regular;
  font-weight: 600;
  border: 2px dashed;
  @include themify('border-color', 'primary');
  &:hover {
    @include themify('color', 'tertiary');
    @include themify('background', 'primary');
  }
}

.secondary {
  @include themify('background', 'secondary');
  @include themify('color', 'background');
  border: 2px solid;
  @include themify('border-color', 'secondary');
  font-weight: 600;
  &:hover {
    border: 2px solid;
    @include themify('color', 'background');
  }
}

.noBorder {
  border: none !important;
}

.secondaryWithoutBorder {
  background: transparent;
  @include themify('color', 'secondary');
  border: 2px solid transparent;
  &:hover {
    text-decoration: underline;
  }
}

.primaryWithoutBorder {
  background: transparent;
  @include themify('color', 'primary');
  border: 2px solid transparent;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
}

.lightGray {
  @include themify('background-color', 'light_gray');
  @include themify('color', 'primary');
  border: 2px solid transparent;

  &:hover {
    text-decoration: underline;
  }
}

.background {
  @include themify('background-color', 'background');
  @include themify('color', 'primary');
  border: 2px solid transparent;

  &:hover {
    text-decoration: underline;
  }
}

.disabled {
  opacity: 0.5;
  border: none !important;
  @include themify('color', 'subtitle');
}
