.msgArea {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

.buttonViewMoreArea {
  display: flex;
  width: 100%;
  justify-content: center;
  button {
    max-width: 180px;
  }
}

.container{
  display: flex;
  width: 100% ;
  flex-direction: column;
}

.btnArea {
  margin-top: 30px;
  display: flex;
  button {
    max-width: 180px;
    margin-right: 20px;
  }
}
