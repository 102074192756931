@use '../../styles/themes/themes.scss';
@import '../../styles/fonts.scss';
@import '../../styles/media_queries.scss';

.usersCategories {
  width: fit-content;
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    font-weight: 300;
    white-space: nowrap;
    margin: 0;
    padding-left: 0;

    li {
      @include themes.themify('color', 'subtitle');
      cursor: pointer;
      float: left;
      font-size: 16px;
      margin-right: 3rem;
      text-decoration: none;
      white-space: nowrap;

      @media (max-width: 992px) {
        margin-left: 0px;
        margin-right: 8%;
      }

      @media(max-width: $width-small){
        margin-right: 3%;
      }

      &.active {
        font-weight: 600;
        font-family: $font-regular;
        border-bottom: 1px solid;
        @include themes.themify('border-bottom-color', 'secondary');
      }
      &:hover {
        cursor: pointer;
        border-bottom: 1px solid;
        @include themes.themify('border-bottom-color', 'secondary');
      }
    }
  }
}

@media (max-width: 992px) {
  .usersCategories {
    width: 100%;
    overflow-x: auto;
    ul {
      li {
        font-size: 15px;
      }
    }
  }
}

@media(max-width: $width-small){
  .usersCategories {
    ul {
      li {
        font-size: 12px;
      }
    }
  }
}
