@import '../../styles/style.scss';
@import '../../styles/themes/themes.scss';
@import '../../styles/media_queries.scss';

.footerContainer {
  min-height: 100px;
  font-family: $font-regular;
  font-weight: 600;
  margin-bottom: 2%;
  @include themify('background-color', 'background');

  @media screen and (max-width: $width-extra-large) {
    padding: 0 2rem;
  }

  @media screen and (min-width: $width-extra-large) {
    padding: 0 9rem;
  }

  .separator {
    @include themify('border-color', 'secondary');
    opacity: 1;
    height: 1px;
    margin-bottom: 5%;
    width: 100%;
  }

  .bodyFooter {
    display: flex;
    .centerContent{
      display: flex;
      justify-content: center;
    }
    .logoNAL {
      width: 200px;
    }

    .margin {
      margin-top: 20px;
    }

    div {
      flex: 1;
      padding: 0 10px;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
      &:nth-child(3) {
        flex: 2;

        .footerLink {
          @include themify('color', 'subtitle');
          opacity: 0.6;
        }
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          margin-bottom: 15px;
        }

        .footerLink {
          text-decoration: none;
          @include themify('color', 'primary');

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .downFooter {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    margin-top: 2%;

    span {
      @include themify('color', 'subtitle');
      opacity: 0.6;
      font-size: 12px;
    }
  }
}

@media (max-width: $width-small) {
  .footerContainer{
    .bodyFooter{
      flex-direction: column-reverse;
      align-items: center;

      div{
        padding: 0;
      }

      ul{
        li{
          text-align: center;
        }
      }
    }
  }
}
