@use 'styles/themes/themes.scss';
@import '../../styles/media_queries.scss';

.mainHeader {
  width: 100%;
  height: 8rem;
  z-index: 100;
  position: fixed;
  top: 0;
  padding: 0 3rem;
  transition: all linear 0.4s;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: $width-small) and (max-width: $width-extra-large) {
    padding: 0 2rem;
  }

  @media screen and (min-width: $width-extra-large) {
    padding: 0 9rem;
  }

  .linkLogo {
    display: flex;
    align-items: center;
    text-decoration: none;

    .logoNAL{
      width: 130px;
    }
  }

  .inlineDiv {
    display: flex;
    align-items: center;

    .wrapperButton{
    }
  }

  .linkContent {
    display: flex;

    .activeNavLink,
    .navLink {
      margin: 0 10px;
      text-decoration: none;

      &:hover {
        border-bottom: 1px solid;
        @include themes.themify('border-bottom-color', 'secondary');
      }
    }
    .activeNavLink {
      border-bottom: 1px solid;
      @include themes.themify('border-bottom-color', 'secondary');
    }
    .navLink {
      border-bottom: none;
    }
  }
}

.backgroundColor {
  @include themes.themify('background', 'background');
  border-bottom: 1px solid;
  @include themes.themify('border-color', 'base_gray');
}

.profileIconContainer {
  display:flex;
  align-items: center;
}

.hideInMobile {
  @media (max-width: 992px) {
    display: none;
  }
}

.showInMobile {
  @media (min-width: 992px) {
    display: none;
  }
}
.menuHamburguer {
  margin: 0 10px;

  .iconMenu {
    @include themes.themify('color', 'secondary');
    font-size: 3rem;
    cursor: pointer;

    height: 18px;
  }

}
