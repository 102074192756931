@use '../../styles/themes/themes.scss';
@use '../../styles/fonts.scss';

.container {
  margin: 0 0 15px;
  .input {
    display: flex;
    cursor: pointer;
    .checkboxIcon {
      width: 15px;
      height: 15px;
      margin: 5px 20px 0 0;
    }
    label {
      @include fonts.get-font-size('label');
      @include themes.themify-fonts('light');
      margin-bottom: 0;
    }
    .checked {
      @include themes.themify('color', 'subtitle');
    }
    .unchecked {
      @include themes.themify('color', 'subtitle');
    }
  }
}
