@use 'src/styles/themes/themes';
@use 'src/styles/fonts';

.select {
  @include themes.themify-fonts('light');
  background-image: url('../../assets/images/chevron-down-afluent.svg');
  background-size: auto;
  background-position: right;
  background-repeat: no-repeat;
}
.noValue {
  @include themes.themify('color', 'input');
}
