@use "src/styles/themes/themes";
@use "src/styles/fonts";

/* The container */
.container {
  width: 100%;
  display: block;
  position: relative;
  padding-left: 35px;
  margin: 0 0 15px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @include themes.themify('color', 'subtitle');
}

.input {
  appearance: auto;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 1px solid;
  @include themes.themify('border-color', 'subtitle');
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.checkmark {
  @include themes.themify('background-color', 'secondary', '&:after');
}

.disabled {
  @include themes.themify('background-color', 'tertiary', '&:after');
}

.disabled {
  @include themes.themify('background-color', 'tertiary');
}
