@import '../../../../styles/themes/themes.scss';
@import '../../../../styles/media_queries.scss';

.generate {
  margin-bottom: 7%;

  a {
    align-items: flex-end;
    display: flex;
    font-size: 25px;
    margin-bottom: 7%;
    text-decoration: none;
    width: 100%;
    border-radius: 3px;
    height: 85px;
    align-items: center;

    &:hover {
      font-weight: bold;
    }

    &.blue {
      @include themify('background', 'primary');
      @include themify('color', 'background');
    }

    &.white {
      @include themify('background', 'background');
      @include themify('color', 'text');
    }

    .icon {
      flex: 1 1;
      margin: 0 2% 0 3%;

      svg {
        margin-right: 2%;
        margin-top: -2px;
      }
    }
    .iconArrow {
      margin-right: 2%;
    }
  }

  .separator {
    @include themify('background', 'tertiary');
    height: 1px;
    margin-bottom: 2%;
    width: 100%;

    &.none {
      display: none;
    }
  }

  @media (max-width: $width-medium) {
    a {
      font-size: 18px;
      height: 65px;
      .icon {
        img {
          margin-right: 6%;
          margin-left: 4%;
        }
      }
      .iconArrow {
        margin-right: 5%;
      }
    }
  }
}

.generate.big {
  margin-bottom: 3.5%;

  a {
    margin-bottom: 3.5%;

    .icon {
      margin: 0 1% 0 1.5%;
    }
  }

  .separator {
    margin-bottom: 1%;
  }
}
