@use '../../styles/utils.scss';

.banner {
  min-height: 50vh;
  position: relative;
  overflow: hidden;
  padding: 8rem 8rem 8rem;
  display: flex;
}

.content {
  align-self: flex-end;
}

.subtitle {
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.background {
  @include utils.absoluteFill();
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  filter: brightness(70%);
}