@use 'styles/themes/themes.scss';
@import '../../styles/fonts.scss';
@import '../../styles/media_queries.scss';

.showInMobile {
  @media (min-width: 992px) {
    display: none;
  }
}
.menuHamburguer {
  margin: 0 10px;
}

.iconMenu {
    @include themes.themify('color', 'secondary');
    font-size: 3rem;
    cursor: pointer;
    height: 18px;
}


.linkLogo {
  display: flex;
  align-items: center;
  text-decoration: none;

  .logoNAL{
    width: 130px;
  }
}

.menuMobileList {
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  @include themes.themify('background', 'background');
  top: 0px;
  left: 0px;
  overflow: auto;

  .listOptions {
    list-style: none;
    li {
      padding: 15px 25px;
      border-bottom: 1px solid;
      @include themes.themify('border-bottom-color', 'tertiary');
      font-size: 2rem;
      @include themes.themify('color', 'link');
      font-family: $font-regular;
      font-weight: 500;
      justify-content: flex-start;
      align-items: center;
      display: flex;

      a {
        @include themes.themify('color', 'link');
        text-decoration: none;
        font-family: $font-regular;
        font-weight: 500;
        width: 100%;
      }
    }
  }

  .buttonArea {
    width: 90%;
    height: 53px;
    margin: 50px auto 0;
  }
}

.headerMenuMobile {
  display: flex;
  padding: 2.5rem 2.5rem;
  justify-content: space-between;
  border-bottom: 1px solid;
  @include themes.themify('border-bottom-color', 'tertiary');
  align-items: center;
}

