.subContainer {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-bottom: 38px;
  }
}

.msgArea {
  display: flex;
  width: 100%;
  margin-top: 20px;
}
