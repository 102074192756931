@import './default.scss';
@import './visa.scss';

$themes: (
  default: $default,
  visa: $visa,
);

$themes_fonts: (
  default: $default_fonts,
  visa: $visa_fonts,
);

@mixin themify($property, $key, $selector: false) {

  @each $theme,
  $colors in $themes {
    &.theme-#{$theme} {
      @if $selector {
        #{$selector} {
          #{$property}: map-get($colors, $key);
        }
      } @else {
        #{$property}: map-get($colors, $key);
      }
    }
  }
}

@mixin themify-repeating-linear-gradient($color, $postfix) {
  @each $theme,
  $colors in $themes {
    &.theme-#{$theme}#{$postfix} {
      background: repeating-linear-gradient(
        117deg,
        transparent 0px,
        transparent 6px,
        #{map-get($colors, $color)} 8px
      );
    }
  }
}

@mixin themify-all-colors($class-prefix, $property: 'color') {
  @each $theme-name, $theme in $themes {
    @each $color-name, $color in $theme {
      .#{$class-prefix}#{"" + $color-name} {
        &.theme-#{$theme-name} {
          #{$property}: $color;
        }
      }
    }
  }
}

@mixin themify-fonts($key) {

  @each $theme,
  $fonts in $themes_fonts {
    &.theme-#{$theme} {
      font-family: map-get($fonts, $key);
    }
  }
}

@mixin themify-linear-gradient($color) {
  @each $theme,
  $colors in $themes {
    &.theme-#{$theme} {
      background: linear-gradient(
        to bottom,
        #{map-get($colors, $color)} 0%,
        #{map-get($colors, $color)} 420px,
        white 420px,
        white 100%
      );
    }
  }
}