@import "../../../styles/fonts.scss";

.Subcontainer {
  & > div {
    padding-bottom: 15px;
  }

  h3{
    margin-bottom: 38px;
  }
}
