@use "src/styles/themes/themes";
@import '../../styles/media_queries.scss';

.body {
  padding-top: 4rem;
  min-height: calc(100vh - 180px);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: $width-extra-large) {
    padding: 3rem 2rem;
  }

  @media screen and (min-width: $width-extra-large) {
    padding: 6rem 9rem;
  }
}

.marginHeader {
  margin-top: 80px;
}
