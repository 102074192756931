@import 'fonts.scss';
@import 'media_queries.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5% !important;
}

html,
body {
  min-height: 100%;
}

body {
  font-size: 1.6rem !important;
  font-family: $font-light, Helvetica, 'Helvetica Neue';
}

button,
input,
textarea {
  font-family: $font-light, Helvetica, 'Helvetica Neue';
}

p {
  margin-bottom: 0;
}