@use 'src/styles/themes/themes';
@use 'src/styles/fonts';
@use 'sass:map';
@import '../../styles/colors.scss';

.react-datepicker__header {
  background: $dark-blue;
  * {
    color: $white;
  }
}

.react-datepicker__current-month {
  font-size: map.get(fonts.$size, 'small') !important;
  line-height: map.get(fonts.$size, 'h6');
}

.react-datepicker-wrapper{
  width: 100%;
}
