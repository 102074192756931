@use 'styles/themes/themes.scss';

.fill {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingSpinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loadingSpinner div {
  transform-origin: 40px 40px;
  animation: ldsSpinner 1.2s linear infinite;

  @include themes.themify('background', 'secondary', '&::after');

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 3px;
    height: 18px;
    border-radius: 5%;
  }
}

.loadingSpinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.loadingSpinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.loadingSpinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.loadingSpinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.loadingSpinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.loadingSpinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.loadingSpinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.loadingSpinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.loadingSpinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.loadingSpinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.loadingSpinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.loadingSpinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes ldsSpinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
