@use '../../styles/themes/themes.scss';
@import '../../styles/media_queries.scss';

.boxProfile {
  position: relative;

  ol, ul {
    padding-left: 0;
  }

  .buttonProfile {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    @include themes.themify('color', 'tertiary');
    @include themes.themify('background', 'primary');
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0px;
    padding: 1px;
    text-transform: uppercase;
  }

  .subMenuEditProfile {
    transition: all linear 0.2s;
    @include themes.themify('background', 'background');
    position: absolute;
    top: 60px;
    z-index: 10;
    right: 0px;
    list-style: none;
    border-right: 1px solid;
    border-left: 1px solid;
    border-bottom: 1px solid;
    @include themes.themify('border-color', 'base_gray');
    .textOption {
      display: flex;
      justify-content: left;
      align-items: center;
    }
    .icon {
      margin-right: 10px;
      width: 30px;
      height: 30px;
      display: inline-block;
      text-align: center;
      padding-top: 2px
    }




    .icon.star {
      img {
        width: 22px;
        height: 22px;
      }
    }
    .iconAwesome {
      @include themes.themify('fill', 'secondary', 'path');
    }
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      padding: 10px;
      min-width: 150px;
      border-bottom: 1px solid;
      text-align: center;
      @include themes.themify('border-color', 'base_gray');
      .iconArrowLeft {
        transform: rotate(180deg);
      }
      a {
        @include themes.themify('color', 'primary');
        text-decoration: none;
        display: block;
        width: 100%;
        height: 100%;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
