@use 'src/styles/utils';
@use 'src/styles/fonts';
@use 'src/styles/themes/themes';
@import '../../styles/media_queries.scss';

.container {
  @media screen and (min-width: $width-extra-large) {
    margin: -6rem -9rem 0;
  }
  @media screen and (max-width: $width-extra-large) {
    margin: -3rem -2rem 0;
  }

  .page {
    min-height: calc(100vh - 350px);
    display: flex;
    flex-direction: column;
    padding: 4rem 9rem;

    @media screen and (max-width: $width-extra-large) {
      padding: 3rem 2rem;
    }
  }
}
