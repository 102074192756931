@use 'src/styles/utils';
@use 'src/styles/fonts';
@use 'src/styles/themes/themes';
@import '../../styles/media_queries.scss';

.content {
  margin-top: 3%;

  .box {
    &:first-child {
      padding-right: 1.5%;
    }
    &:last-child {
      padding-left: 1.5%;
    }
  }

  .adaptiveBox {
    width: 100%;
  }
}

.center {
  display: flex;
  justify-content: center;
}

.spaces {
  padding: 10px;

  @media (max-width: 850px) {
    padding: 0px;

    & > div {
      padding-bottom: 10px;
    }
  }
}

.buttonStyle {
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 5rem;
    height: 2.5rem;
  }
}

.icon {
  @include themes.themify('fill', 'secondary', 'path');
}

.infosContent {
  display: flex;
  align-items: center;
}

@media (max-width: $width-medium) {
  .content {
    margin-top: 6%;
  }
  .infosContent.big {
    flex-direction: column;
  }
}

@media (max-width: $width-small) {
  .content {
    margin-top: 6%;
  }
  .infosContent {
    flex-direction: column;
  }
}
