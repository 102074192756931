@import '../../styles/fonts';
@import '../../styles/themes/themes.scss';

.container {
  display: flex;
  flex-direction: column;
  @include themify('color', 'subtitle');
  font-size: 16px;

  label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: bold;
    font-family: $font-regular;
    @include themify('color', 'primary');
  }

  p {
    line-height: 21px;
    margin-bottom: 22px;
  }
}
