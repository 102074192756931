@use '../../styles/themes/themes.scss';

.line{
  margin: 30px 0 50px;
  width: 100%;
  height: 1px;
  border-top: 1px;
  opacity: 1;
  @include themes.themify('background', 'secondary');
}
