@use '../../styles/themes/themes.scss';

.dropdown {
  margin: 0 15px;
  display: inline-block;
  position: relative;
  @include themes.themify('color', 'primary');

  button {
    background: none;
    border: none;
  }

  .icon {
    margin-left: 5px;
  }

  .dropdownOptions {
    display: none;
    position: absolute;
    overflow: auto;
    border: solid 1px #aaa;
    background-color: #fff;

    span {
      padding: 0 5px;
      cursor: pointer;
    }
    span:hover {
      background: #eee;
    }
  }

  .isOpen {
    display: flex;
    flex-direction: column;
  }
}