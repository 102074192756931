@import '../../styles/media_queries.scss';

.modalOpen {
  overflow: hidden;
  height: 100vh;
}

.modal {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  overflow: auto;

  background: rgba(0, 0, 0, 0.3);

  display: flex;
  justify-content: center;
  align-items: center;

  .modalContent {
    background: #fff;
    padding: 30px 60px;
    position: relative;
    max-width: 1400px;
    min-height: 300px;
    width: 80%;

    .modalHeader {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      h3 {
        font-size: 33px;
      }

      .close {
        height: 25px;
        cursor: pointer;

        &:hover{
          opacity: 0.6;
        }
      }
    }
    .boxContent {
      margin: 50px 0;
    }
  }
}

@media(max-width: $width-medium){
  .modal{
    display: block;
    .modalContent{
      min-height: 100vh;
      width: 100%;
      padding: 20px;
    }
  }
}
