@media print {
  .table {
    .header {
      width: 100%;
      display: flex;
      justify-content: space-around;
      margin-bottom: 1cm;
      .cell {
        font-weight: 600;
        text-align: center;
      }
    }
    .body {
      .item {
        display: flex;
        justify-content: space-around;
        padding-bottom: 0.5cm;
        margin-bottom: 0.5cm;
        border-bottom: 1px solid #ccc;
        .cell {
          margin-bottom: 0.3cm;
        }
      }
    }
  }
}
