@use 'src/styles/utils';
@use 'src/styles/fonts';
@use 'src/styles/themes/themes';
@import '../../styles/media_queries.scss';

.Container {
  margin-bottom: 30px;
}

.items {
  align-items: center;
  margin-bottom: 40px;
}

.filterAera {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  .icon {
    width: 30px;
    height: 30px;
    display: inline-block;
    text-align: center;
    padding-top: 2px;
  }

  .iconFill {
    @include themes.themify('fill', 'secondary', 'path');
  }

  .iconNoFill {
    @include themes.themify('fill', 'primary', 'path');
  }

  @media (max-width: $width-small) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.searchInputs {
  display: flex;
  align-items: center;
  border-bottom: 1px solid;

  input {
    border-bottom: 0px;
  }
}

.selectInputs {
  display: flex;

  select {
    flex-grow: 1;
  }
}
