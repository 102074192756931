@import '../../../../styles/themes/themes.scss';
@import '../../../../styles/fonts';

.container {
  width: 100%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  padding: 0 2%;

  .accordeonHeader {
    display: flex;
    width: 100%;
    align-items: center;
    height: 76px;
    @include themify('border-color', 'const_gray');

    .boxNames,
    .boxNames2 {
      display: flex;
      align-items: center;
      flex: 1 1;

      .text {
        @include themify('color', 'subtitle');
        font-size: 1.6rem;
        margin: 0 5px;
      }

      @media (max-width: 850px) {
        width: 100%;

        flex-direction: column;
        padding-top: 10px;
        padding-right: 0px;
        padding-bottom: 11px;
      }
    }
    .boxNames {
      .text {
        &:nth-child(1) {
          flex: 1 1;
        }
        &:nth-child(2) {
          flex: 2 2;
        }
      }
    }
    .boxNames2 {
      .text {
        &:nth-child(1) {
          flex: 2 2;
        }
        &:nth-child(2) {
          flex: 1 1;
          display: flex;
          justify-content: center;
        }
      }
    }

    .moreInformation {
      display: flex;
      align-items: center;
      cursor: pointer;
      @include themify('color', 'secondary');

      .more_info {
        font-family: $font-regular;
        font-weight: 600;
        font-size: 1.6rem;
        @include themify('color', 'primary');
      }
      .icon {
        @include themify('color', 'secondary');
        height: 20px;
        width: 20px;
      }
    }

    @media (max-width: 850px) {
      flex-direction: column;
      height: 180px;
    }

    @media (max-width: 575px) {
      height: 200px;
    }
  }

  .content {
    margin: 30px 0 10px;
    .footer {
      padding: 15px 0;
      border-top: 1px solid;
      @include themify('border-color', 'secondary');
      display: flex;
      width: 100%;
      margin: 20px 0;

      button {
        width: 180px;
      }
    }
  }
}

.boldText {
  font-family: $font-semibold;
}

.modalFooter{
  display: flex;
  margin-top: 25px;

  .wrapperButton{
    width: 180px;
  }
}
